/* ################################################################################################################################################################################################ */
/* General Settings */
/* ################################################################################################################################################################################################ */

@font-face {
  font-family: 'Iconfont';
  src: url(assets/fonts/iconfont.ttf) format('TrueType');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Iconfont_more_Symbols';
  src: url(assets/fonts/Iconfont_moreSymbols.ttf) format('TrueType');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'Iconfont_more_Symbols';
  content: attr(data-icon);
}

html {
  scroll-behavior: smooth;
}

.ff {
  font-family: Iconfont;
}

.arrow-down:before {
  content: '\e901';
  font-style: normal;
}
.arrow-up:before {
  content: '\e902';
  font-style: normal;
}
.arrow-left:before {
  content: '\e903';
  font-style: normal;
  font-size: 1rem;
}
.arrow-right:before {
  content: '\e904';
  font-style: normal;
  font-size: 1rem;
}
.playbutton:before {
  font-size: 12px;
  content: '\e905';
  font-style: normal;
}
.pausebutton:before {
  font-size: 12px;
  content: '\e906';
  font-style: normal;
}
.fullscreenicon:before {
  font-family: Iconfont;
  content: '\e908';
  font-style: normal;
  font-size: 1rem;
}
.thumbsup:before {
  content: '\e907';
  color: #75e9dd;
  font-style: normal;
  cursor: pointer;
  font-size: 1.5rem;
}
.thumbsup:hover::before {
  content: '\e907';
  color: #8efbf0;
  font-style: normal;
  cursor: pointer;
}
.thumbsup:active::before {
  content: '\e907';
  color: #fb1026;
  font-style: normal;
  cursor: pointer;
}
.thumbsup_voted:before {
  content: '\e907';
  color: #e2e6e1;
  font-style: normal;
  font-size: 1.5rem;
}

a {
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

/* Farbe der Links normalisieren und Linkstyle entfernen */
a,
a:hover {
  text-decoration: none;
}

body {
  position: relative;
  font-size: 20px;
  line-height: 24px;
  border-top: none;
  color: #454545;
  height: auto;
}

/* Abstand der Linien erhöhen um Collapsibles besser von einander zu trennen */

hr {
  margin-top: 15px;
  margin-bottom: 15px;
  opacity: 0.2;
}

/* Semantic Override */

h1:first-child {
  margin-top: 5px;
}
.ui.dimmer.fullscreen {
  position: fixed;
}

/* Headline */

h1 {
  font-weight: normal;
  font-size: 1.325em;
  line-height: 1.2666em;
  margin-bottom: 0px;
}

/* Subheadline */

h2 {
  font-weight: normal;
  font-size: 1em;
  margin-top: 10px;
}

/* Semantic Override */

h2:last-child {
  margin-bottom: 23px;
}

/* Benutzt für Podium */
h4 {
  font-size: 1em;
  line-height: 1.125em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

/* Semantic Einstellungen für Margins des Grids überschreiben */

.ui.grid {
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}

.ui.list {
  font-size: 0.825em;
}

/* Abstand zu dem Bereich der Logos */

.ui.grid > .row_contentheader {
  align-items: flex-end;
}

.ui.grid.content_header {
  margin-top: 25px;
}

/* Abstand des Contents nach oben hin erhöhen */

.ui.grid.stackable {
  margin-top: 0px;
  margin-bottom: 80px;
}

/* Abstand zu den aktuelle Slide Button erhöhen */
.ui.grid > .row .hr_podium {
  overflow: hidden;
}

/* Semantic Einstellung für Padding der Rows fixen */
.ui.grid > .row {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Abstand der 2. Row zum Player/Slides erhöhen */
.ui.grid > .row.optionals {
  margin-top: 15px;
}

/* Wartetafel */

.hr_wartediv {
  height: 100%;
  background: rgb(244, 244, 244, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 2rem;
  position: relative;
}

.hr_wartetext {
  margin-bottom: 0px !important;
}

.box {
  max-height: 480px;
  /* height: 25vw; */
  margin-bottom: 35px;
}

.rightBox {
  aspect-ratio: 1.77;
}

.rightBox .hr_collapsible_uaq,
.rightBox hr {
  display: none;
}

.rightBox .hr_collapsed_uaq {
  height: auto !important;
  margin-top: -20px;
}

.overflow {
  overflow: auto;
}

/* Serveranzeige */

.server {
  font-size: 12px;
  text-align: end;
  opacity: 0.17;
}

/* ################################################################################################################################################################################################ */
/* Ende General Settings */
/* ################################################################################################################################################################################################ */

/* ################################################################################################################################################################################################ */
/* Body */
/* ################################################################################################################################################################################################ */

.main {
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
}
.hr_wrapper {
  padding-left: 4.167%;
  padding-right: 4.167%;
  background-color: #e8e8e4;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

/* ################################################################################################################################################################################################ */
/* Ende Body */
/* ################################################################################################################################################################################################ */

/* ################################################################################################################################################################################################ */
/* Setting Logos/Buttons for switching languages */
/* ################################################################################################################################################################################################ */

/* Bereich der Logos. Feste Höhe von 100px gesetzt */

.hr_headline {
  /* Headline für Mobile Ansicht -- Da im aktuellen Header auf der Rechten Seite die VoD Knöpfe angezeigt werden */
  margin-top: 15px;
}

.hr_logos {
  width: 100%;
  max-width: 1920px;
  height: 130px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  padding-left: min(4.167%, 80px);
  padding-right: min(4.167%, 80px);
  z-index: 3;
}
.hr_logos_fixed {
  position: fixed;
}
.hr_logos_top_spacer {
  margin-top: 130px;
}

.hr_logo_left {
  width: calc(100% / 3);
  display: flex;
  align-items: center;
}

.hr_logo_center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3);
}

.hr_logo_right {
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.media_library {
  display: flex;
  align-items: center;
}

.media_library.hamburger {
  padding: 24px 0px;
  border-top: rgb(239, 239, 239) 1px solid;
}
.media_library_arrow_left {
  width: 25px;
  height: 18px;
  mask-image: url('assets/images/media_library/arrowLeft.svg');
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
}
.media_library_arrow_right {
  transform: rotate(180deg);
  width: 25px;
  height: 18px;
  mask-image: url('assets/images/media_library/arrowLeft.svg');
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
}
.hamburgerContent .media_library:hover .media_library_arrow_left {
  margin-right: 0;
}

.media_library:hover .media_library_arrow_left {
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
}
.media_library:hover .media_library_arrow_right {
  margin-left: 10px;
  transition: all 0.2s ease-in-out;
}

.media_library_text,
.hr_logo_right .media_library_text {
  margin: 0 0 0 10px;
}
.hr_logo_left .media_library_text {
  margin: 0 36px 0 10px;
}

/* hamburger und Inhalt anfang */
.hamburgerWindow.hamburgerInactive {
  height: 0;
  transition: height;
  transition-delay: 0.5s;
}
.hamburgerActive {
  height: calc(100% - 184px);
  width: 100% !important;
  margin-right: 0px;
}

.hamburgerInactive .hamburgerContent {
  margin-right: -50%;
}
.hamburgerInactive .hamburgerDimmer {
  opacity: 0;
}

.hamburgerNav {
  display: block;
  height: 17px;
  width: 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.hr_logo_right .hamburgerNav {
  margin-left: 25px;
}
.hamburgerNav.leftSide {
  margin-right: 25px;
}
.hamburgerNav .line {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 10px;
}
.hamburgerNav .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburgerNav .line2 {
  transition: transform 0.2s ease-in-out;
}

.hamburgerNav .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.hamburgerNav.active .line1 {
  transform: rotate(45deg);
}

.hamburgerNav.active .line2 {
  transform: scaleY(0);
}

.hamburgerNav.active .line3 {
  transform: rotate(-45deg);
}

.hamburgerDimmer {
  transition: opacity 0.5s;
  width: 100%;
  background-color: lightgray;
  opacity: 0.3;
}

.hamburgerWindow {
  height: calc(100vh - 130px);
  display: flex;
  transition: margin 0.3s;
  width: 100%;
  overflow: hidden;
  max-width: 1920px;
  /* position: absolute; */
  position: fixed;
  top: 130px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
}

.hamburgerContent {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  right: 0;
  width: 50%;
  background-color: var(--color-background);
  height: 100%;
  transition: margin 0.5s;
  padding-left: 4.167%;
  padding-right: 4.167%;
  overflow-y: auto;
}
.hamburgerContent .hamburger_headline {
  margin-top: 23px;
}

.hamburgerContent .languages {
  padding: 12px 0px;
  transition: 0.3s;
}

.hamburgerContent .hr_collapsible_description {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 12px 0px;
  cursor: pointer;
}
.hr_collapsible_description .entry_headline {
  align-items: center;
  display: flex;
  padding-bottom: 6px;
  z-index: 8;
}

.hr_collapsible_description .eventDescription {
  width: 100%;
  display: flex;
  color: #52555c;
  z-index: 7;
}

.hamburgerContent .moreEvents {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 20px;
  border-top: rgb(239, 239, 239) 1px solid;
  padding: 12px 0px;
  font-weight: bold;
}
.hamburgerContent .entry {
  border-bottom: rgb(239, 239, 239) 1px solid;
  cursor: pointer;
}

.hamburgerContent .logout {
  display: flex;
  width: 100%;
  font-size: 20px;
  padding: 24px 0px;
  margin-bottom: 80px;
  font-weight: bold;
  cursor: pointer;
}

.logout i {
  margin-left: 5px;
}

/* hamburger und Inhalt ende */
/* language dropdown anfang */

.langDropDown {
  cursor: pointer;
  text-align: center;
}
.hr_logo_right .langDropDown {
  margin-left: 36px;
}
.dropDown {
  width: 130px;
  visibility: hidden;
  transition: visibility 0.2s ease-in-out 0ms, opacity 0.2s ease-in-out;
  opacity: 0;
  margin-top: 10px;
  position: absolute;
  z-index: 1000;
}
.downActive {
  visibility: visible;
  opacity: 1;
}
.dropDown::after {
  bottom: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 8px;
  left: calc(50% - 8px);
}
.activeEntry {
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dropDownArrow::before {
  content: '\e903';
  font-family: Iconfont;
}
.dropDownArrow {
  margin: 0px 15px;
  transition: transform 0.4s ease-in-out;
  transform: rotate(270deg);
}
.rotateDown {
  transform: rotate(270deg) scaleX(-1);
}
.entry {
  padding: 12px;
  align-items: center;
}
img#secondLogo {
  width: 120px;
  box-sizing: border-box;
  position: relative;
  height: auto;
}
/* language dropdown ende */
.img_lang {
  height: 22px;
  width: 22px;
  margin: auto 5px auto 5px;
  cursor: pointer;
}
.logo_center_url .languages {
  display: flex;
  justify-content: center;
}

.logo_right_url .languages {
  display: block;
}
.hr_logo_right .languages {
  margin: 0 0 0 36px;
}
.languages {
  display: flex;
}
.langActive {
  border: 2px solid #43d7bc;
  border-radius: 11px;
}
.headerControl {
  display: flex;
  align-items: center;
}
/* ################################################################################################################################################################################################ */
/* ENDE Setting Logos/Buttons for switching languages */
/* ################################################################################################################################################################################################ */

/* ################################################################################################################################################################################################ */
/* Player */
/* ################################################################################################################################################################################################ */

.vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 2.5em !important;
  height: 2.5em !important;
  border-radius: 50% !important;
  line-height: 2.41667em !important;
  background-color: rgba(210, 210, 210, 0.7) !important;
  border: 0.04em solid rgba(200, 200, 200, 0.541) !important;
  font-size: 3em !important;
  margin: unset !important;
}

.vjs-http-source-selector {
  font-size: small;
}
.vjs-http-source-selector .vjs-menu-button .vjs-menu .vjs-menu-content {
  font-size: xx-small;
  bottom: 1.2em;
}
.vjs-http-source-selector > .vjs-menu-button > .vjs-icon-placeholder::before {
  font-family: VideoJS;
  font-weight: normal;
  font-style: normal;
  content: "\f114";
  font-size: 1.4em;
}
.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  background-color: #2B333F;
  background-color: rgba(43, 51, 63, 0.7);
  position: absolute;
  width: 100%;
  bottom: 0.8em!important;
  max-height: 15em;
  overflow: unset!important;
}
.vjs-controll-bar {
  cursor: default;
}

/* ################################################################################################################################################################################################ */
/* ENDE Player */
/* ################################################################################################################################################################################################ */

/* ################################################################################################################################################################################################ */
/* Slide-History */
/* ################################################################################################################################################################################################ */

.hr_dropdown_slides {
  margin-top: 0.5px;
  display: flex;
  align-items: flex-end;
}

.hr_collapsible_slides {
  width: 50%;
}

.hr_btn_collapsible {
  padding: 0px;
  text-decoration: none;
  display: flex;
  align-items: center;
  border: 0px;
  background-color: transparent;
  cursor: pointer;
}

.hr_current_slide {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.btn_current_slide,
.hr_strawpoll_submit {
  padding: 0.5em 1.5em;
  background-color: white;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}
.btn_current_slide span {
  font-size: 1em;
  line-height: 1em;
  justify-content: center;
  display: flex;
}

.slide_container {
  border: 1px solid rgb(218, 218, 218);
}

/* ################################################################################################################################################################################################ */
/* ENDE Slide-History */
/* ################################################################################################################################################################################################ */
/* ################################################################################################################################################################################################ */
/* User ask Questions */
/* ################################################################################################################################################################################################ */

.hr_uaq_grid {
  display: grid;
  grid-template-columns: 155px auto;
  margin-top: 16px;
  row-gap: 10px;
}

.hr_uaq_textbox {
  width: 100%;
  max-width: 355px;
  line-height: 1.3;
  padding: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b99a9a;
  opacity: 1;
}

/*Description Box is under the Podium and not in the hamburger(Sidebar) */
.description_box{
  border-top: 1px solid  #d3d3d3;
  padding-top: 20px;
  margin-top: 20px;
}

.hr_lbl_author {
  grid-area: 1/1;
}

.hr_uaq_input_author {
  grid-area: 1/2;
}

.hr_lbl_location {
  grid-area: 2/1;
}

.hr_uaq_input_location {
  grid-area: 2/2;
}

.hr_lbl_message {
  grid-area: 3/1;
}

.hr_uaq_text_message {
  grid-area: 3/2 / span 2 / span 1;
  min-height: 100px;
}

.hr_uaq_grid_submit {
  grid-area: 4/1;
  align-self: end;
  width: 115px;
  height: 40px;
  background: #f8f4f4 0% 0% no-repeat padding-box;
  border: 1px solid #d3d3d3;
  opacity: 1;
  cursor: pointer;
}

.hr_strawpoll_submit {
  padding: 15px 30px;
  background: #f8f4f4 0% 0% no-repeat padding-box;
  cursor: pointer;
  margin-top: 10px;
}

.privacy_policy {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin: 10px;
  font-size: 0.75em;
}

/* Ab hier weitere Aufräumarbeit */

/* ################################################################################################################################################################################################ */
/* ENDE User ask Questions */
/* ################################################################################################################################################################################################ */
/* ################################################################################################################################################################################################ */
/* User vote Questions */
/* ################################################################################################################################################################################################ */
.votingHeadline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 28px;
  line-height: 20px;
}
/* ################################################################################################################################################################################################ */
/* ENDE User vote Questions */
/* ################################################################################################################################################################################################ */
/* ################################################################################################################################################################################################ */
/* VoD Buttons Top Right Corner */
/* ################################################################################################################################################################################################ */

.hr_emergency {
  color: #b99a9a;
  margin-top: 3.5px;
  width: 100%;
  text-align: left;
  font: normal normal bold 1em/1.125em Arial;
}

.vod_nav_buttons {
  font-size: 0.875em;
}

.vod_buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 1em;
  height: 40px;
}

.vod_buttons_switch {
  font-size: 1em;
  color: white;
  border-radius: 6px;
  border: 1px solid #000;
  padding: 11px 20px;
  min-width: 120px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}
.arrowLeft::after {
  font-family: Iconfont;
  content: '\e903';
  font-size: 15px;
  margin-right: 5px;
}
.arrowRight::after {
  font-family: Iconfont;
  content: '\e904';
  font-size: 15px;
  margin-left: 5px;
}
.vod_nav_buttons {
  font-size: 1em;
  color: rgba(196, 234, 233, 1);
  /* background-color: rgba(26, 171, 168, 1); */
  /* border-radius: 4px;
  border: 1px solid rgba(224, 224, 224, 1); */
  padding: 0 10px 0 10px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}
.vod_nav_buttons_padding_left {
  padding-left: 0;
}
.vod_nav_control {
  display: flex;
  align-items: baseline;
}
.vod_nav_questions {
  color: #849ec2;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 3px;
}
.vod_nav_questions_placeholder {
  margin-top: 20px;
}
.vod_nav_questions_mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.vod_nav_questions_mobile .center {
  min-width: 143px;
  display: flex;
  align-items: flex-start;
  margin-left: 35px;
}
.vod_nav_questions_mobile .center .sync.alternate.icon {
  margin-left: 15px;
}
.sync.alternate.icon {
  cursor: pointer;
  margin-left: 10px;
}

/* .left_side_margin_top {
  margin-top: 10px !important;
} */

.fragen1bis10 {
  color: rgba(118, 118, 118, 1);
  font-size: 1em;
  align-items: center;
  margin: 0px 15.8px 0px 16px;
}

.hr_questions_total {
  color: rgba(118, 118, 118, 1);
  font-size: 1em;
  margin-top: 2px;
  display: flex;
  align-items: center;
}

/* ################################################################################################################################################################################################ */
/* ENDE VoD Buttons Top Right Corner */
/* ################################################################################################################################################################################################ */

/* ################################################################################################################################################################################################ */
/* Footer */
/* ################################################################################################################################################################################################ */

.ui.grid.hr_bottom {
  border-top: 2px solid white;
  margin-top: auto;
}

.hr_footer > .two.wide.column {
  width: 12.5% !important;
}

.hr_stoerung_link {
  white-space: nowrap;
}

.ui.grid > .row.hr_footer,
.ui.grid > .row.hr_links {
  font-size: 0.825em;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 4.546%;
  padding-right: 4.546%;
  align-items: center;
}

.ui.grid > .row.optional_link {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 4.546%;
  padding-right: 4.546%;
}

.optional_link a {
  white-space: nowrap;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

/* ################################################################################################################################################################################################ */
/* ENDE Footer */
/* ################################################################################################################################################################################################ */

/* ################################################################################################################################################################################################ */
/* Slideflow */
/* ################################################################################################################################################################################################ */

lse-slideflow-list button.ff {
  font-size: 25px;
  background: transparent;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

lse-slideflow-list button.ff.ff.arrow-left {
  margin-right: 10px;
}
lse-slideflow-list button.ff.ff.arrow-right {
  margin-left: 10px;
}

lse-slideflow-list button.ff:hover {
  transition: ease 0.5s;
}

.coverflow_style {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  display: flex;
  align-items: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.coverflow_style::-webkit-scrollbar {
  display: none;
}
.mini_div {
  display: inline-block;
}

.mini-highlight {
  opacity: 1 !important;
}

/* ################################################################################################################################################################################################ */
/* Ende Slideflow */
/* ################################################################################################################################################################################################ */

/* ################################################################################################################################################################################################ */
/* VoD Auswahl der Frage */
/* ################################################################################################################################################################################################ */

.hr_vod_box {
  padding: 23px 0 !important;
}

.metadata {
  display: flex;
  /* align-items: center; */
  padding: 0.5em 2rem;
}

.hr_vod_TC {
  margin-left: 28px;
}

.hr_vod_playbutton {
  margin-right: 15px;
}

.hr_voting_box {
  background-color: #fafafa;
}

.hr_vod_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* ################################################################################################################################################################################################ */
/* ENDE VoD Auswahl der Frage */
/* ################################################################################################################################################################################################ */

/* ################################################################################################################################################################################################ */
/* Drop Down Styles */
/* ################################################################################################################################################################################################ */

/* WICHTIG FÜR IOS APPLE WILL ES NICHT ANDERS UND KANN ES NICHT ANDERS!!!!!! */

.hr_collapsed_uaq,
.hr_collapsed_podium,
.hr_collapsed_question_vod,
.hr_collapsed_slides,
.hr_collapsed_strawpoll,
.hr_collapsed_voting {
  overflow: hidden;
}

.arrow {
  width: 1.65rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
  margin-right: 1rem;
}

.arrow span {
  top: 0.5rem;
  position: absolute;
  width: 1rem;
  height: 0.17rem;
  display: inline-block;
  background-color: #000;
  transition: all 0.2s ease;
}
.arrow span:first-of-type {
  left: 0px;
  transform: rotate(45deg);
}

.arrow span:last-of-type {
  right: 1px;
  transform: rotate(-45deg);
}

.arrow.active span:first-of-type {
  transform: rotate(-45deg);
}

.arrow.active span:last-of-type {
  transform: rotate(45deg);
}

/* ################################################################################################################################################################################################ */
/* ENDE Drop Down Styles */
/* ################################################################################################################################################################################################ */

.btnLogin > button {
  padding: 10px 20px;
  background: #353060 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 25px;
  color: #fff;
  opacity: 1;
}

.hr_emergency_mobile {
  color: #b99a9a;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  font: normal normal bold 1em/1.125em Arial;
}

.btn_show_questions {
  margin-left: 15px;
}

/* .hr_headline {
  display: inline-block;
  width: 50%;
  text-align: left;
  margin-top: 6.5px;
} */

.adminNotices {
  padding-left: 4.546% !important;
  padding-right: 4.546% !important;
  margin-bottom: 10px;
  width: 100%;
}

.debug {
  word-break: break-word;
}

.debug span {
  color: #929292;
  font-size: 0.85em;
}

canvas {
  margin-top: 30px;
}

.margintop {
  margin-top: 20px;
}

.votingResultVoted {
  margin-top: 40px;
}

.votingResultVoted div:nth-child(2) {
  margin-top: 10px;
}

.votingResultVoted div:nth-child(1) {
  font-size: 0.85em;
}

.hr_radioButtonText {
  margin-left: 25px;
}

.cur_slide_left,
.cur_slide_right,
.uaq_button_left,
.uaq_button_right,
.strawpoll_button_left,
.strawpoll_button_right,
.btn_login_left,
.btn_login_right,
.btn_switch_left,
.btn_switch_right {
  display: none;
}

.hr_slides_vod {
  margin-bottom: 23px !important;
}

.onlyPlayer {
  justify-content: center;
}

.onlyPlayer > .hr_podium {
  width: 100%;
  padding: 0px;
}

.fullPlayer {
  max-width: 1280px;
  width: 100%;
  margin-bottom: 35px !important;
  padding: 0px !important;
}

@media only screen and (min-width: 1440px) {
  .hr_wrapper {
    padding: 0 140px;
  }
}

@media only screen and (max-width: 1439px) {
  .hr_wrapper {
    max-width: 1280px;
  }
}

@media only screen and (min-width: 1024px) {
  .ui.grid > .row .hr_podium {
    margin-top: 15px;
  }

  /* .hr_collapsed_podium {
    height: auto !important;
  } */

  .hr_collapsed_podium {
    font-size: 0.9em;
  }

  .hr_wartediv {
    padding-top: calc(577 / 1027 * 100%);
  }
  .hr_wartetext {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  body {
    font-size: 18px;
    line-height: 21px;
  }

  .ui.grid.stackable {
    margin-bottom: 40px;
  }

  .content_header {
    margin-bottom: 5px;
  }

  h1 {
    margin-bottom: 8px;
  }

  h1:first-child {
    margin-top: 7.5px;
  }

  .vod_buttons_switch {
    padding: 12px 15px;
  }
  .vod_nav_questions {
    margin-top: 12px;
  }

  .hr_uaq_content input[type='submit'] {
    width: 78px;
  }

  .hr_collapsed_uaq label {
    width: 103px;
    min-width: 103px;
  }
}

@media only screen and (max-width: 1023px) {
  body {
    font-size: 18px;
    line-height: 21px;
  }

  .thumbsup:before {
    font-size: 2rem;
  }
  .thumbsup_voted:before {
    font-size: 2rem;
  }

  .votes {
    margin-bottom: 20px;
  }

  .leftBox {
    max-height: unset;
    height: unset;
    margin-bottom: 35px;
  }

  .vjs-big-play-button {
    font-size: 2.82em !important;
  }

  .hr_btn_collapsible {
    margin: 0px;
  }

  .optionals {
    padding-top: 0px !important;
  }
  .hr_wrapper {
    padding-left: 3.908%;
    padding-right: 3.908%;
  }
  /* .content_header {
    margin-bottom: 4.38%;
  } */
  .scrollbar {
    display: block;
  }

  h1 {
    margin-bottom: 12px;
  }

  h4 {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .hr_player {
    max-width: none;
  }
  .hr_headline_collapsibles h2 {
    margin-bottom: 0.8em !important;
    line-height: 14px;
  }
  .hr_podium #hr_collapsible_podium h2:last-child {
    margin-bottom: 0.5em !important;
  }
  .ui.grid > .row > .hr_headline {
    width: 100% !important;
  }

  .hr_slides_vod {
    display: none;
  }
  #player {
    padding: 0px 0px 42px 0px !important;
  }
  .ui.stackable.grid > .row > .wide.column {
    padding: 0 !important;
  }

  .hr_collapsed_podium h4:first-child {
    margin-top: 20px;
  }
  .hr_current_slide {
    width: 100%;
    margin-top: 20px;
  }

  .hr_collapsible_slides {
    width: 100%;
  }

  /* .ff.arrow-left,
  .ff.arrow-right {
    font-size: 26px;
    line-height: 26px;
  } */

  .hr_vod_TC {
    margin-left: 0px;
  }

  .hr_vod_mobile_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px 10px 0px;
  }

  lse-slideflow-list > button.ff {
    display: none;
  }

  .hr_wartediv {
    height: 50.7vw;
  }
  .hamburgerActive {
    height: calc(100vh - 130px);
  }
  .hamburgerContent {
    width: 100%;
    overflow-x: hidden;
  }
  .hamburgerInactive .hamburgerContent {
    margin-right: -1023px;
  }
  .sign.out {
    padding-right: 4%;
  }
  .hamburgerDimmer {
    width: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .ui.grid > .row.hr_footer {
    padding-left: 4.239%;
    padding-right: 4.239%;
  }
  .ui.grid > .row.hr_links {
    color: #e0e0e0;
    padding-left: 4.239%;
    padding-right: 4.239%;
  }

  .ui.grid > .row {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 512px) {
  body {
    font-size: 20px;
    line-height: 24px;
  }

  .vjs-big-play-button {
    font-size: 2.4em !important;
  }

  h1:first-child {
    margin-top: 0px;
  }
  h1 {
    margin-bottom: 9px;
  }
  h2 {
    margin-top: 13px;
    margin-bottom: 0px;
  }

  .hr_wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .hr_logos {
    padding: 0px 22px;
  }

  .hr_headline {
    padding-left: 4.69%;
    padding-right: 4.69%;
  }

  .hr_emergency_mobile {
    padding-left: 4.69%;
    padding-right: 4.69%;
  }

  .hamburgerContent,
  .hr_collapsible_podium,
  .hr_collapsed_podium,
  .hr_collapsible_voting,
  .hr_collapsible_slides,
  .hr_dropdown_slides,
  .hr_slide_history,
  .hr_collapsible_uaq,
  .hr_collapsed_uaq,
  .hr_collapsible_questions_vod,
  .hr_collapsible_strawpoll,
  .hr_collapsed_strawpoll,
  .hr_collapsed_question_vod {
    padding-left: 4.69%;
    padding-right: 4.69%;
  }

  .hr_grid {
    margin-top: 0px !important;
  }

  .hr_vod_content {
    display: block;
    justify-content: unset;
    margin-top: 15px;
  }

  .hr_vod_question {
    min-height: 100px;
  }

  .playbutton:before,
  .pausebutton:before {
    font-size: 40px;
    line-height: 1.5em;
  }

  .hr_vod_playbutton {
    margin-right: 0px;
  }

  #player {
    padding-bottom: 0px !important;
  }

  .ui.stackable.grid > .row > .two.wide.column {
    padding-top: 0px !important;
  }

  /* .ui.stackable.grid > .row > .wide.column {
    padding-top: 26px !important;
  } */

  .slide_collapsible {
    padding-left: 4.69%;
    padding-right: 4.69%;
  }

  .ui.stackable.grid > .row > .wide.column.hr_tablet_question_vod {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }

  .hr_current_slide {
    width: 100%;
  }

  .ui.grid {
    margin-bottom: 0px;
  }

  hr {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  lse-slideflow-list {
    overflow-x: auto;
  }

  .ui.grid > .row.hr_footer,
  .ui.grid > .row.hr_links {
    padding-left: 4.69%;
    padding-right: 4.69%;
  }

  .ui.grid.hr_bottom {
    margin-left: 0px;
    margin-right: 0px;
  }
  .ui.grid > .row.hr_links {
    color: #e0e0e0;
  }

  .ui.grid > .row {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .hr_uaq_grid {
    grid-template-columns: 100px auto;
  }

  .hr_uaq_grid_submit {
    grid-area: 5/1 / span 1 / span 2;
    justify-self: flex-end;
  }

  .hr_wartediv {
    height: 55.7vw;
    padding: 0 20px;
  }

  .votingResultVoted {
    display: block;
  }

  .votingResultVoted div {
    width: 100%;
    margin-top: 20px;
  }
}

.hide-page-numbers ::ng-deep .ngx-pagination li:not(.pagination-previous):not(.pagination-next) {
  display: none;
}
