button,
html [type="button"],
[type="reset"],
[type="submit"] {
  outline: none;
}

.ui.grid {
  margin-left: "";
  margin-right: "";
}

.ui.grid > .column:not(.row),
.ui.grid > .row > .column {
  padding-left: 0px;
  padding-right: 0px;
}

.ui.grid > .row > [class*="two wide"].column,
.ui.grid > .column.row > [class*="two wide"].column,
.ui.grid > [class*="two wide"].column,
.ui.column.grid > [class*="two wide"].column {
  width: 3.028% !important;
}

.ui.grid > .row > [class*="three wide"].column,
.ui.grid > .column.row > [class*="three wide"].column,
.ui.grid > [class*="three wide"].column,
.ui.column.grid > [class*="three wide"].column {
  width: 16.162% !important;
}
.ui.grid > .row > [class*="seven wide"].column,
.ui.grid > .column.row > [class*="seven wide"].column,
.ui.grid > [class*="seven wide"].column,
.ui.column.grid > [class*="seven wide"].column {
  width: 48.486% !important;
}

@media only screen and (max-width: 1023px) {
  .ui.stackable.grid {
    width: auto;
    margin-left: 0em !important;
    margin-right: 0em !important;
  }

  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.grid > .stackable.stackable.row > .column {
    width: 100% !important;
    margin: 0em 0em !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding: 1rem 1rem !important;
  }

  .ui.stackable.grid:not(.vertically) > .row {
    margin: 0em;
    padding: 0em;
  }

  /* Coupling */

  .ui.container > .ui.stackable.grid > .column,
  .ui.container > .ui.stackable.grid > .row > .column {
    padding-left: 0em !important;
    padding-right: 0em !important;
  }

  /* Don't pad inside segment or nested grid */

  .ui.grid .ui.stackable.grid,
  .ui.segment:not(.vertical) .ui.stackable.page.grid {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  /* Divided Stackable */

  .ui.stackable.divided.grid > .row:first-child > .column:first-child,
  .ui.stackable.celled.grid > .row:first-child > .column:first-child,
  .ui.stackable.divided.grid > .column:not(.row):first-child,
  .ui.stackable.celled.grid > .column:not(.row):first-child {
    border-top: none !important;
  }

  .ui.inverted.stackable.celled.grid > .column:not(.row),
  .ui.inverted.stackable.divided.grid > .column:not(.row),
  .ui.inverted.stackable.celled.grid > .row > .column,
  .ui.inverted.stackable.divided.grid > .row > .column {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .ui.stackable.celled.grid > .column:not(.row),
  .ui.stackable.divided:not(.vertically).grid > .column:not(.row),
  .ui.stackable.celled.grid > .row > .column,
  .ui.stackable.divided:not(.vertically).grid > .row > .column {
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .ui.stackable.celled.grid > .row {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .ui.stackable.divided:not(.vertically).grid > .column:not(.row),
  .ui.stackable.divided:not(.vertically).grid > .row > .column {
    padding-left: 0em !important;
    padding-right: 0em !important;
  }
}
button, input, optgroup, select, textarea {
  font-family: sans-serif;
  font-size: 0.85em;
  line-height: 0.9em;
  margin: 0;
}

.ui.form .field > label {
  color: inherit;
}